import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  companyName = environment.companyName;
  companyPhoneDisplay = environment.companyPhoneDisplay;
  companyPhoneDial = environment.companyPhoneDial;
  companyLocationLink = environment.companyLocationLink;

  constructor() { }
  
  ngOnInit() {

  }
}
