import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-taptocall',
  templateUrl: './taptocall.component.html',
  styleUrls: ['./taptocall.component.css']
})
export class TaptocallComponent implements OnInit {

  companyLocationLink = environment.companyLocationLink;
  companyPhoneDisplay = environment.companyPhoneDisplay;
  companyPhoneDial = environment.companyPhoneDial;
  //companyPhone800Display = environment.companyPhone800Display;
  //companyPhone800Dial = environment.companyPhone800Dial;

  constructor() { }

  ngOnInit() {
  }

}
