// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  companyName: 'Your Driver HK',
    companyEmail: 'YourDriver@gmail.com',
    companyAddress: 'Hong Kong',
    companyPhoneDisplay: '(852) 590 59 590',
    companyPhoneDial: '(852) 5905 9590',
    companyLocationLink: 'http://maps.apple.com/?daddr=Hong+Kong',
    techContact: 'yeelut@gmail.com',
    siteVersion: '210401.0'
};
