import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { TaptocallComponent } from './components/taptocall/taptocall.component';
import { TaptocontactComponent } from './components/taptocontact/taptocontact.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FullslateComponent } from './components/fullslate/fullslate.component';
import { AppointmentstickyComponent } from './components/appointmentsticky/appointmentsticky.component';
import { FreeconsultComponent } from './components/freeconsult/freeconsult.component';

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": "yourdriverhk.com"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#4caf50",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "We use cookies to make our site work better for you. ",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "/privacy",
    "policy": "Cookie Policy"
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    PrivacyComponent,
    ServicesComponent,
    ContactComponent,
    FooterComponent,
    TaptocallComponent,
    TaptocontactComponent,
    FullslateComponent,
    AppointmentstickyComponent,
    FreeconsultComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TabsModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
