import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  companyName = environment.companyName;
  siteVersion = environment.siteVersion;
  companyPhoneDisplay = environment.companyPhoneDisplay;
  copyDate = new Date().getFullYear()

  constructor() { }

  ngOnInit() {
  }

}
