import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freeconsult',
  templateUrl: './freeconsult.component.html',
  styleUrls: ['./freeconsult.component.css']
})
export class FreeconsultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
